import * as httpApi from '@/http/api'
import http from '@/http/axios'
import OSS from 'ali-oss'

export async function getOssKey() {
  let accessKeyId = sessionStorage.getItem('accessKeyId');
  let accessKeySecret = sessionStorage.getItem('accessKeySecret');
  if (!accessKeyId || !accessKeySecret) {
    const { code, data } = await http.get(httpApi.default.getOssKey);
    if (code === 0) {
      accessKeyId = data.accessKeyId;
      accessKeySecret = data.accessKeySecret;
      sessionStorage.setItem('accessKeyId', accessKeyId);
      sessionStorage.setItem('accessKeySecret', accessKeySecret);
    }
  }
  return {
    accessKeyId,
    accessKeySecret
  }
}

export function getEnv() {
  switch (RUNENV) {
    case 'test':
      return 'test';
    case 'dev':
      return 'development';
    case 'pre':
      return 'pre';
    case 'master':
      return 'production';
    default:
      return 'development';
  }
}

const classificationTypes = {
  images: { 
    dir: 'images',
    match: ['image/gif', 'image/bmp', 'image/jpg', 'image/jpeg', 'image/png', 'image/webp'], 
    prefix: 'IMG'
  },
  audios: {
    dir: 'audios',
    match: ['audio/midi', 'audio/mpeg', 'audio/x-mpegurl', 'audio/x-m4a', 'audio/webm', 'audio/ogg', 'audio/x-realaudio' ,'audio/midi', 'audio/x-wav'],
    prefix: 'VIDEO'
  },
  videos: {
    dir: 'videos',
    match: ['video/webm', 'video/ogg', 'video/mp4', 'video/mpeg', 'video/x-msvideo', 'video/x-ms-wmv', 'video/x-flv', 'video/x-m4v', 'video/quicktime'],
    prefix: 'VIDEO'
  },
  docs: {
    dir: 'docs',
    prefix: 'DOC'
  }
}

export async function upload(file, fileName) {
  const { accessKeyId, accessKeySecret } = await getOssKey()
  const client = new OSS({
    region: 'oss-cn-hangzhou',
    accessKeyId,
    accessKeySecret,
    bucket: 'nongbo-resource'
  });


  let classificationType = classificationTypes.docs.dir
  let prefix =  classificationTypes.docs.prefix
  for (const [, value] of Object.entries(classificationTypes)) {
    if (value?.match?.includes(file.type)) {
      classificationType = value.dir
      prefix = value.prefix
      break
    }
  }

 
  const env = getEnv()
  const match = /.([^\/\\\.]*)$/.exec(file.name)
  const fileType = match[1]
  const putPath = `/${env}/${classificationType}/${fileName ? fileName : `${prefix}_${new Date().getTime()}_${parseInt(Math.random() * 100000)}`}.${fileType}` 

  return client.put(putPath, file).then((res)=>{
    return res
  })
}

